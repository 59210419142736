import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";
import { AuthGuard } from "./guard/auth.guard";
import { LeaveGuard } from "./leaved/leaved.guard";

const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "purchaserequistion",
        loadChildren: () =>
          import("./pages/purchaserequistion/purchaserequistion.module").then(
            (m) => m.purchaserequistionModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "manage-purchaserequistion",
        loadChildren: () =>
          import(
            "./pages/manage-purchaserequistion/manage-purchaserequistion.module"
          ).then((m) => m.ManagepurchaserequistionModule),
        canActivate: [AuthGuard],
      },
      {
        path: "manage-userpassword",
        loadChildren: () =>
          import("./pages/manage-userpassword/manage-userpassword.module").then(
            (m) => m.ManageUserpasswordModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "potracker",
        loadChildren: () =>
          import("./pages/potracker/potracker.module").then(
            (m) => m.PotrackerModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "manage-potracker",
        loadChildren: () =>
          import("./pages/manage-potracker/manage-potracker.module").then(
            (m) => m.ManagePotrackerModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "procurement",
        loadChildren: () =>
          import("./pages/procurement/procurement.module").then(
            (m) => m.ProcurementModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "manage-procurement",
        loadChildren: () =>
          import("./pages/manage-procurement/manage-procurement.module").then(
            (m) => m.ManageProcurementModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "scrap",
        loadChildren: () =>
          import("./pages/scrap/scrap.module").then((m) => m.ScrapModule),
        canActivate: [AuthGuard],
      },
      {
        path: "manage-scrap",
        loadChildren: () =>
          import("./pages/manage-scrap/manage-scrap.module").then(
            (m) => m.ManageScrapModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "invoicevalidation",
        loadChildren: () =>
          import("./pages/invoicevalidation/invoicevalidation.module").then(
            (m) => m.InvoicevalidationModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "manage-invoicevalidation",
        loadChildren: () =>
          import(
            "./pages/manage-invoicevalidation/manage-invoicevalidation.module"
          ).then((m) => m.ManageInvoicevalidationModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "login",
        loadChildren: () =>
          import("./pages/login/login.module").then((m) => m.LoginModule),
      },
      {
        path: "reset",
        loadChildren: () =>
          import("./pages/reset/reset.module").then((m) => m.ResetModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "dashboard",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
