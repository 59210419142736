
export const environment = {
  production: false,
  baseURL: 'https://api.ebuddy.sdga.in/api/index.php/',
  mediaURL: 'https://api.ebuddy.sdga.in/api/uploads/',
  onesignal: {
    appId: 'YOUR_APP_ID',
    googleProjectNumber: 'GOOGLE_PROJECT_NUMBER',
    restKey: 'REST_KEY'
  },
  general: {
    symbol: '',
    code: 'INR'
  },
  authToken: 'ebuddy'
};
