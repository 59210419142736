import { Injectable } from "@angular/core";

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  icon: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: "Master",
    main: [
      {
        state: "dashboard",
        name: "Dashboard",
        type: "link",
        icon: "ti-home",
      },
      {
        state: "purchaserequistion",
        name: "Purchase Requistion",
        type: "link",
        icon: "ti-save-alt",
      },
      {
        state: "potracker",
        name: "PO Tracker",
        type: "link",
        icon: "ti-envelope",
      },
      {
        state: "procurement",
        name: "Procurement",
        type: "link",
        icon: "ti-layout-list-large-image",
      },
      {
        state: "scrap",
        name: "Scrap",
        type: "link",
        icon: "ti-id-badge",
      },
     	{
        state: 'invoicevalidation',
        name: 'Invoice Validation',
        type: 'link',
        icon: 'ti-receipt'
      },
    ],
  },
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
